/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
<template>
  <section id="users">
    <p>Gerenciamento de usuários</p>
    <div>
      <vue-excel-xlsx
        v-if="excel_init == true"
        style="margin: 10px; border-color: #3b65f0 !important; color: white; background-color: #3b65f0 !important; text-align: center; border: 1px solid transparent; padding: 0.786rem 1.5rem; border-radius: 0.358rem;"
        :data="items_filtred"
        :columns="columnsExcel"
        :filename="'Associados'"
        :sheetname="'associados'"
      >
        Exportar associados
      </vue-excel-xlsx>
      <add-new-user
        :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
        @onSaved="onSaved($event)"
        @close="isAddNewUserSidebarActive = false"
      />

      <edit-user
        :is-edit-user-sidebar-active.sync="isEditUserSidebarActive"
        :data="rowData"
        @onSaved="onSaved($event)"
        @close="isEditUserSidebarActive = false"
      />

      <detail-user
        :is-detail-user-sidebar-active.sync="isDetailUserSidebarActive"
        :data="rowData"
        @close="isDetailUserSidebarActive = false"
      />

      <pass-user
        :is-pass-user-sidebar-active.sync="isPassUserSidebarActive"
        :data="rowData"
        @onSaved="onSaved($event)"
        @close="isPassUserSidebarActive = false"
      />

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrar</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>usuários</label>
            </b-col>

            <b-col
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              cols="12"
              md="4"
            >
              <label class="m-2">Associado</label>
              <v-select
                id="list"
                v-model="selectValueAssociado"
                class="w-75"
                :options="getYears()"
                :reduce="option => option.value"
                label="text"
                @input="filterByColumn($event,'Returnassociate')"
              />
            </b-col>

            <b-col
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              cols="12"
              md="4"
            >
              <label class="m-2">Papel</label>
              <v-select
                id="role"
                v-model="selectValuePapel"
                class="w-75"
                :options="[
                  {text:'Administrador',value:'ADMIN'},
                  {text:'Mestre',value:'MASTER'},
                  {text:'Pagador(a)', value: 'PAID'},
                  {text:'Comum', value: 'COMMON'},
                  {text:'Secretário(a)', value: 'SECRETARY'},
                  {text:'Secretário(a) Geral', value: 'SECRETARY-GENERAL'},
                  {text:'Tesoureiro', value: 'TREASURER'},
                  {text:'Presidente', value: 'PRESIDENT'},
                  {text:'Vice-Presidente', value: 'VICE-PRESIDENT'},
                  {text:'Palestrante', value: 'SPEAKER'},
                  {text:'Patrocinador', value: 'SPONSOR'},
                  {text:'Diretor Científico', value: 'SCIENTIFIC-DIRECTOR'},
                  {text:'Diretor do Curso', value: 'COURSE-DIRECTOR'},
                  {text:'Diretor de Comunicação', value: 'COMMUNICATION-DIRECTOR'},
                  {text:'Administrador do Congresso', value: 'CONGRESS-ADMINISTRATOR'},
                  {text:'Professor', value: 'PROFESSOR'},
                  {text:'Orientador', value: 'ADVISOR'},
                  {text:'Patrocinador de Comunicação', value: 'SPONSOR-COMMUNICATION'},
                  {text:'Patrocinador do Congresso', value: 'CONGRESS-SPONSOR'},
                  {text:'Jurídico', value: 'JURIDICAL'},
                  {text:'Presidente de Estado', value: 'STATE-PRESIDENT'},
                  {text:'Associado SNNO', value: 'SNNO-ASSOCIATE'},
                  {text:'Gerente de Arte', value: 'ART-MANAGER'},
                ]"
                :reduce="option => option.text"
                label="text"
                @input="filterByColumn($event)"
              />
            </b-col>
            <!-- Search -->
            <b-col
              class="mb-1 mb-md-0 mt-2"
              cols="12"
              md="12"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchValue"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
                  @keyup="filterInTable($event.target.value)"
                />
                <b-button
                  variant="primary"
                  @click="isAddNewUserSidebarActive = true"
                >
                  <span class="text-nowrap">Adicionar usuário</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="overflow-auto">
          <b-table
            id="mytable"
            ref="mytable"
            hover
            :items="items_filtred"
            :fields="tableColumnsCongress"
            primary-key="id"
            :total-rows="rows"
            :per-page="perPage"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :current-page="currentPage"
            show-empty
            empty-text="Nenhuma informação encontrada"
            busy.sync="isBusy"
            empty-filtered-text="Nenhum registro filtrado"
          >
            <template slot="empty">
              <div
                v-if="isBusy"
                class="text-center"
              >
                <b-spinner class="align-middle" />
              </div>
              <div
                v-else
                class="text-center"
              >
                Nenhuma informação encontrada
              </div>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="edit(data.item)">
                  <feather-icon
                    icon="EditIcon"
                  />
                  <span
                    class="align-middle ml-50"
                  >Editar</span>
                </b-dropdown-item>

                <b-dropdown-item @click="detail(data.item)">
                  <feather-icon
                    icon="EyeIcon"
                  />
                  <span
                    class="align-middle ml-50"
                  >Detalhes</span>
                </b-dropdown-item>

                <b-dropdown-item @click="password(data.item)">
                  <feather-icon
                    icon="KeyIcon"
                  />
                  <span
                    class="align-middle ml-50"
                  >Mudar Senha</span>
                </b-dropdown-item>

                <b-dropdown-item @click="remove(data.item)">
                  <feather-icon
                    icon="TrashIcon"
                  />
                  <span
                    class="align-middle ml-50"
                  >Deletar</span>
                </b-dropdown-item>

                <b-dropdown-item @click="anuidade(data.item)">
                  <feather-icon
                    icon="CreditCardIcon"
                  />
                  <span
                    class="align-middle ml-50"
                  >Anuidade</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <!-- <span class="text-muted">A partir de {{ dataMeta.from }} para {{ dataMeta.to }} de {{ dataMeta.of }} palestrantes</span> -->
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </div>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import AddNewUser from './AddNewUser.vue'
import EditUser from './EditUser.vue'
import DetailUser from './DetailUser.vue'
import PassUser from './PassUser.vue'
import UserService from './usersService'
import { mapActions } from 'vuex'
import Vue from 'vue'

export default {

  components: {
    AddNewUser,
    EditUser,
    DetailUser,
    PassUser,
    // EditSpeaker,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    // BBadge,
    // BDropdown,
    // BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const isAddNewUserSidebarActive = ref(false)
    const isEditUserSidebarActive = ref(false)
    const isDetailUserSidebarActive = ref(false)
    const isPassUserSidebarActive = ref(false)
    return {
      isAddNewUserSidebarActive,
      isEditUserSidebarActive,
      isDetailUserSidebarActive,
      isPassUserSidebarActive
    }
  },
  data() {
    return {
      excel_init: false,
      items: [],
      items_filtred: [],
      is_filtredByColumn: false,
      is_filterSearch: false,
      tableColumnsCongress: [
        {
          key: 'id',
          field: 'id',
          label: 'Código',
          sortable: true
        },
        {
          key: 'name',
          field: 'name',
          label: 'Nome',
          sortable: true
        },
        {
          key: 'email',
          field: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'cpf',
          field: 'cpf',
          label: 'CPF',
          sortable: true,
        },
        {
          key: 'Returnrole',
          field: 'Returnrole',
          label: 'Papel',
          sortable: true,
        },
        {
          key: 'Returnassociate',
          field: 'Returnassociate',
          label: 'Associado',
          sortable: true,
        },
        {
          key: 'actions',
          field: 'actions',
          label: 'Ações',
          sortable: false,
        }
      ],
      rows: 0,
      perPage: 10,
      currentPage: 1,
      nextPage: this.currentPage + 1,
      prevPage: this.currentPage - 1,
      sortBy: 'id',
      sortDesc: true,
      events: [],
      perPageOptions: [10, 25, 50, 100, 200, 300, 400, 500],
      selectValueAssociado: '',
      selectValuePapel: '',
      searchValue: '',
      isBusy: false,
      isRemoving: false,
      rowData: {},
      columns: [
        {
          label: 'Product',
          field: 'product',
        },
        {
          label: 'Price',
          field: 'price',
          dataFormat: this.priceFormat
        },
        {
          label: 'Quantity',
          field: 'quantity',
        },
      ],
      data: [
        {
          product: 'Beverage',
          price: 10,
          quantity: 2
        },
        {
          product: 'Snack',
          price: 12,
          quantity: 6
        },
        {
          product: 'Beverage',
          price: 10,
          quantity: 5
        },
        {
          product: 'Snack',
          price: 12,
          quantity: 3
        }
      ],
    }
  },
  computed: {
    columnsExcel() {
      return this.tableColumnsCongress.filter(item => item.key !== 'actions')
    }
  },
  watch: {

  },
  beforeMount() {
    this.listUsers()
  },
  methods: {
    getYears() {
      const years = [{ value: 'Não encontrado', text: 'Não encontrado' }]
      const finalYear = new Date().getFullYear() + 1
      let startYear = 2009
      while (startYear !== finalYear) {
        startYear += 1
        years.push({ value: startYear, text: startYear })
      }
      return years
    },
    filterByColumn() {
      this.selectValueAssociado = this.selectValueAssociado === null ? '' : this.selectValueAssociado
      this.selectValuePapel = this.selectValuePapel === null ? '' : this.selectValuePapel

      if (this.selectValueAssociado !== '' || this.selectValuePapel !== '') {
        let res = false
        const filter = this.searchValue !== '' ? this.items_filtred : this.items
        const result = filter.filter((item) => {
          if (this.selectValueAssociado !== '' && this.selectValuePapel !== '') {
            res = ((item.Returnassociate !== null ? item.Returnassociate.match(this.selectValueAssociado) : false)
            && (item.Returnrole !== null ? item.Returnrole === this.selectValuePapel : false))
          } else if (this.selectValueAssociado !== '') {
            res = item.Returnassociate !== null ? item.Returnassociate.match(this.selectValueAssociado) : false
          } else if (this.selectValuePapel !== '') {
            res = item.Returnrole !== null ? item.Returnrole === this.selectValuePapel : false
          }
          this.is_filterSearch = !!res
          return res
        })
        this.items_filtred = Vue.observable(result)
      } else {
        this.items_filtred = this.items
        this.filterInTable(this.searchValue)
        this.is_filtredByColumn = false
      }

      this.rows = this.items_filtred.length
    },
    filterInTable(value) {
      if (value.trim() !== '') {
        const filter = this.selectValueAssociado !== '' || this.selectValuePapel !== '' ? this.items_filtred : this.items
        const result = filter.filter((item) => item.name.toLowerCase().match(value.trim().toLowerCase())
          || (item.crm !== null ? item.crm.match(value.trim()) : false)
          || (item.email !== null ? item.email.toLowerCase().match(value.trim().toLowerCase()) : false)
          || (item.cpf !== null ? item.cpf.match(value.trim()) : false)
          || (item.rg !== null ? item.rg.match(value.trim()) : false))
        this.is_filterSearch = true
        this.items_filtred = Vue.observable(result)
      } else {
        this.is_filterSearch = false
        this.items_filtred = this.items
        this.filterByColumn()
      }

      this.rows = this.items_filtred.length
    },
    priceFormat(value) {
      return `$ ${value}`
    },
    onSaved(saved) {
      this.listUsers()
    },
    listUsers() {
      const params = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      }
      this.items = []
      this.isBusy = true
      UserService().fetchUsers(params).then(data => {
        this.items = data.response.users
        this.items_filtred = this.items
        this.rows = data.response.users.length
        this.items.forEach((user, index) => {
          if (user.associate.length === 0) {
            user.Returnassociate = 'Não encontrado'
          } else {
            // eslint-disable-next-line array-callback-return
            user.associate.map((value, key) => {
              user.Returnassociate = value.year
            })
          }
          if (user.role === 'MASTER') {
            user.Returnrole = 'Mestre'
          } else if (user.role === 'ADMIN') {
            user.Returnrole = 'Administrador'
          } else if (user.role === 'PAID') {
            user.Returnrole = 'Pagador(a)'
          } else if (user.role === 'COMMON') {
            user.Returnrole = 'Comum'
          } else if (user.role === 'SECRETARY') {
            user.Returnrole = 'Secretário(a)'
          } else if (user.role === 'SECRETARY-GENERAL') {
            user.Returnrole = 'Secretário(a) Geral'
          } else if (user.role === 'TREASURER') {
            user.Returnrole = 'Tesoureiro'
          } else if (user.role === 'PRESIDENT') {
            user.Returnrole = 'Presidente'
          } else if (user.role === 'VICE-PRESIDENT') {
            user.Returnrole = 'Vice-Presidente'
          } else if (user.role === 'SPEAKER') {
            user.Returnrole = 'Palestrante'
          } else if (user.role === 'SPONSOR') {
            user.Returnrole = 'Patrocinador'
          } else if (user.role === 'SCIENTIFIC-DIRECTOR') {
            user.Returnrole = 'Diretor Científico'
          } else if (user.role === 'COURSE-DIRECTOR') {
            user.Returnrole = 'Diretor do Curso'
          } else if (user.role === 'COMMUNICATION-DIRECTOR') {
            user.Returnrole = 'Diretor de Comunicação'
          } else if (user.role === 'CONGRESS-ADMINISTRATOR') {
            user.Returnrole = 'Administrador do Congresso'
          } else if (user.role === 'PROFESSOR') {
            user.Returnrole = 'Professor'
          } else if (user.role === 'ADVISOR') {
            user.Returnrole = 'Orientador'
          } else if (user.role === 'SPONSOR-COMMUNICATION') {
            user.Returnrole = 'Patrocinador de Comunicação'
          } else if (user.role === 'CONGRESS-SPONSOR') {
            user.Returnrole = 'Patrocinador do Congresso'
          } else if (user.role === 'JURIDICAL') {
            user.Returnrole = 'Jurídico'
          } else if (user.role === 'STATE-PRESIDENT') {
            user.Returnrole = 'Presidente de Estado'
          } else if (user.role === 'SNNO-ASSOCIATE') {
            user.Returnrole = 'Associado SNNO'
          } else if (user.role === 'ART-MANAGER') {
            user.Returnrole = 'Gerente de Arte'
          } else {
            user.Returnrole = 'Desconhecido'
          }
        })
      }).catch(err => console.log(err))
        .finally(() => { this.isBusy = false })
      this.excel_init = true
    },
    password(data) {
      this.rowData.id = data.id
      this.isPassUserSidebarActive = true
    },
    detail(data) {
      this.rowData.id = data.id
      this.rowData.name = data.name
      this.rowData.cpf = data.cpf
      this.rowData.role = data.role
      this.rowData.email = data.email
      this.rowData.phone = data.phone
      this.rowData.profession = data.profession
      this.rowData.birthDate = this.$moment(data.birthDate).format()
      this.rowData.crm = data.crm
      this.rowData.crm_uf = data.crm_uf
      this.rowData.active = data.active
      this.isDetailUserSidebarActive = true
    },
    edit(data) {
      this.rowData.id = data.id
      this.rowData.name = data.name
      this.rowData.cpf = data.cpf
      this.rowData.role = data.role
      this.rowData.email = data.email
      this.rowData.phone = data.phone
      this.rowData.profession = data.profession
      this.rowData.birthDate = this.$moment(data.birthDate).format()
      this.rowData.crm = data.crm
      this.rowData.crm_uf = data.crm_uf
      this.rowData.active = data.active
      this.isEditUserSidebarActive = true
    },
    anuidade(data) {
      const value = [
        {
          name: 'Anuidade Associado',
          user_id: data.id,
          amount: 1,
          value: 10000
        }
      ]

      const customer = {
        name: data.name,
        cpf: data.cpf,
        email: data.email,
        phone: data.phone,
        user_id: data.id,
        birthDate: data.birthDate ? this.$moment(data.birthDate).format() : '',
      }

      // customer
      localStorage.setItem('customer', JSON.stringify({}))
      localStorage.setItem('customer', JSON.stringify(customer))

      localStorage.setItem('items', JSON.stringify([]))
      localStorage.setItem('items', JSON.stringify(value))
      window.location.replace('/administrador/usuarios/pagamento')
    },
    remove(data) {
      this.$swal({
        title: 'Deletar usuário?',
        text: 'Você não será capaz de reverte essa ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim, desejo deletar!',
        showLoaderOnConfirm: true,
        preConfirm: () => UserService().deleteUsers(data.id).then(response => {
          if (response.status === 200) {
            const new_items = this.items.filter((v) => v.id !== data.id)
            this.items = new_items
          }
        }).catch(err => {
          this.$swal.fire(
            'Deletado!',
            'Erro ao tentar deletar.',
            'error'
          )
        })
          .finally(() => {
            this.isBusy = false
          }),
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
            'Deletado!',
            'Congresso deletado com sucesso.',
            'success'
          )
        }
      })
    }
  },
}
</script>

<style lang="scss">
</style>
