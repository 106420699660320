<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar usuário
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submit()"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            v-slot="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-group
              label="Nome"
              label-for="name"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            v-slot="{ errors }"
            name="email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="email"
                v-model="form.email"
                :autocomplete="false"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- CPF -->
          <validation-provider
            v-slot="{ errors }"
            name="cpf"
            rules="required"
          >
            <b-form-group
              label="CPF"
              label-for="cpf"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="cpf"
                v-model="form.cpf"
                v-mask="'###.###.###-##'"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Papel -->
          <validation-provider
            v-slot="{ errors }"
            name="role"
            rules="required"
          >
            <b-form-group
              label="Papel"
              label-for="role"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="role"
                v-model="form.role"
                :options="[
                  {text:'Administrador',value:'ADMIN'},
                  {text:'Mestre',value:'MASTER'},
                  {text:'Pagador(a)', value: 'PAID'},
                  {text:'Comum', value: 'COMMON'},
                  {text:'Secretário(a)', value: 'SECRETARY'},
                  {text:'Secretário(a) Geral', value: 'SECRETARY-GENERAL'},
                  {text:'Tesoureiro', value: 'TREASURER'},
                  {text:'Presidente', value: 'PRESIDENT'},
                  {text:'Vice-Presidente', value: 'VICE-PRESIDENT'},
                  {text:'Palestrante', value: 'SPEAKER'},
                  {text:'Patrocinador', value: 'SPONSOR'},
                  {text:'Diretor Científico', value: 'SCIENTIFIC-DIRECTOR'},
                  {text:'Diretor do Curso', value: 'COURSE-DIRECTOR'},
                  {text:'Diretor de Comunicação', value: 'COMMUNICATION-DIRECTOR'},
                  {text:'Administrador do Congresso', value: 'CONGRESS-ADMINISTRATOR'},
                  {text:'Professor', value: 'PROFESSOR'},
                  {text:'Orientador', value: 'ADVISOR'},
                  {text:'Patrocinador de Comunicação', value: 'SPONSOR-COMMUNICATION'},
                  {text:'Patrocinador do Congresso', value: 'CONGRESS-SPONSOR'},
                  {text:'Jurídico', value: 'JURIDICAL'},
                  {text:'Presidente de Estado', value: 'STATE-PRESIDENT'},
                  {text:'Associado SNNO', value: 'SNNO-ASSOCIATE'},
                  {text:'Gerente de Arte', value: 'ART-MANAGER'},
                ]"
                :reduce="option => option.value"
                label="text"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Profession -->
          <validation-provider
            v-slot="{ errors }"
            name="profession"
            rule="required"
          >
            <b-form-group
              label="Profissão"
              label-for="profession"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-select
                id="profession"
                v-model="form.profession"
                :options="professions"
                :state="errors.length > 0 ? false:null"
                name="profession"
                label="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Phone -->
          <validation-provider
            v-slot="{ errors }"
            name="phone"
          >
            <b-form-group
              label="Celular"
              label-for="phone"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="phone"
                v-model="form.phone"
                v-mask="'(##) #####-####'"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Birth Date -->
          <validation-provider
            v-slot="{ errors }"
            name="birthDate"
          >
            <b-form-group
              label="Nascimento"
              label-for="birthDate"
              :state="errors.length > 0 ? false : null"
            >
              <datepicker
                id="birthDate"
                v-model="form.birthDate"
                input-class="form-control"
                :language="ptBR"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- CRM -->
          <validation-provider
            v-slot="{ errors }"
            name="crm"
            rules="integer|required_if:profession,medico_oftalmologista"
          >
            <b-form-group
              label="CRM"
              label-for="crm"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="crm"
                v-model="form.crm"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- CRM UF -->
          <validation-provider
            v-slot="{ errors }"
            name="crm_uf"
            rules="required_if:profession,medico_oftalmologista"
          >
            <b-form-group
              label="CRM UF"
              label-for="crm_uf"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="crm_uf"
                v-model="form.crm_uf"
                :options="states"
                :reduce="option => option.value"
                label="text"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Active -->
          <validation-provider
            v-slot="{ errors }"
            name="active"
            rules="required"
          >
            <b-form-group
              label="Ativado"
              label-for="active"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="active"
                v-model="form.active"
                :options="[{text:'Sim',value:1},{text:'Não', value: 0}]"
                :reduce="option => option.value"
                label="text"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- password -->
          <validation-provider
            v-slot="{ errors }"
            name="Password"
            vid="password"
            rules="required|min:6"
          >
            <b-form-group
              label-for="register-password"
              label="Senha"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="register-password"
                  v-model="form.password"
                  class="form-control-merge"
                  :autocomplete="'new-password'"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false:null"
                  name="register-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- password confirm -->
          <validation-provider
            v-slot="{ errors }"
            name="repeatPassword"
            vid="repeatPassword"
            rules="required|confirmed:password"
          >
            <!-- repeat password -->
            <b-form-group
              label-for="register-repeat_password"
              label="Repetir senha"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="register-repeatPassword"
                  v-model="form.repeatPassword"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false:null"
                  :autocomplete="'new-password'"
                  name="register-repeat_password"
                  placeholder="············"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isBusy"
            >
              <b-spinner v-if="isBusy" />
              <template v-else>
                Adicionar
              </template>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip
} from 'bootstrap-vue'
import Datepicker from 'vuejs-datepicker'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required, min_value as minValue, email, integer, required_if, min, confirmed
} from 'vee-validate/dist/rules'
import Ripple from 'vue-ripple-directive'
import usersService from './usersService'
import vSelect from 'vue-select'

extend('confirmed', {
  ...confirmed,
  message: 'Senha de confirmação diferente da senha.',
})

extend('min', {
  ...min,
  message: 'Mínimo de caracteres são 6.',
})

extend('required_if', {
  ...required_if,
  message: 'Esse campo é obrigatório.',
})

extend('integer', {
  ...integer,
  message: 'Esse aceita apenas números.',
})

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})

extend('email', {
  ...email,
  message: 'Digite um email válido.',
})

extend('min_length_deposit', {
  ...minValue,
  message: 'O valor minímo para depositar é R$ 1,00.',
})

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    Datepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      ptBR,
      professions: [
        { value: 'medico_oftalmologista', text: 'Médico' },
        { value: 'academico_medicina', text: 'Estudante acadêmico' },
        { value: 'resident', text: 'Residente' },
        { value: 'palestrante', text: 'Palestrante' },
        { value: 'fellow', text: 'Fellow' },
        { value: 'sbao', text: 'Administração (SBAO)' },
        { value: 'up70year', text: 'Acima dos 70' },
        { value: 'areas_afins', text: 'Outros profissionais' },
      ],
      states: [
        { value: '', text: 'Digite o estado' },
        { value: 'AC', text: 'AC - Acre' },
        { value: 'AL', text: 'AL - Alagoas' },
        { value: 'AP', text: 'AP - Amapá' },
        { value: 'AM', text: 'AM - Amazonas' },
        { value: 'BA', text: 'BA - Bahia' },
        { value: 'CE', text: 'CE - Ceará' },
        { value: 'DF', text: 'DF - Distrito Federal' },
        { value: 'ES', text: 'ES - Espírito Santo' },
        { value: 'GO', text: 'GO - Goiás' },
        { value: 'MA', text: 'MA - Maranhão' },
        { value: 'MT', text: 'MT - Mato Grosso' },
        { value: 'MS', text: 'MS - Mato Grosso do Sul' },
        { value: 'MG', text: 'MG - Minas Gerais' },
        { value: 'PA', text: 'PA - Pará' },
        { value: 'PB', text: 'PB - Paraíba' },
        { value: 'PR', text: 'PR - Paraná' },
        { value: 'PE', text: 'PE - Pernambuco' },
        { value: 'PI', text: 'PI - Piauí' },
        { value: 'RJ', text: 'RJ - Rio de Janeiro' },
        { value: 'RN', text: 'RN - Rio Grande do Norte' },
        { value: 'RS', text: 'RS - Rio Grande do Sul' },
        { value: 'RO', text: 'RO - Rondônia' },
        { value: 'RR', text: 'RR - Roraima' },
        { value: 'SC', text: 'SC - Santa Catarina' },
        { value: 'SP', text: 'SP - São Paulo' },
        { value: 'SE', text: 'SE - Sergipe' },
        { value: 'TO', text: 'TO - Tocantins' },
      ],
      form: {
        name: null,
        email: null,
        cpf: null,
        role: null,
        profession: null,
        phone: null,
        birthDate: null,
        crm: null,
        crm_uf: null,
        active: null,
        password: null,
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
  },
  created() {
  },
  methods: {
    resetForm() {
      this.form = {
        name: null,
        email: null,
        cpf: null,
        role: null,
        profession: null,
        phone: null,
        birthDate: null,
        crm: null,
        crm_uf: null,
        active: null,
        password: null,
        repeatPassword: null,
      }
    },
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            this.isBusy = true
            if (this.form.birthDate) {
              this.form.birthDate = this.$moment(this.form.birthDate).format('YYYY-MM-DD')
            }
            usersService().sendUsers(this.form).then((response) => {
              if (response.status === 400) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: response.message,
                    text: ` ERROR: ${response.errors[0][0]} #${response.errors[0][1]}`,
                    variant: 'danger',
                  }
                })
              } else if (response.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
                this.$emit('onSaved', true)
              }
            }).catch((err) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: 'InfoIcon',
                  title: err.message,
                  variant: 'danger',
                }
              })
            })
              .finally(() => { this.isBusy = false })
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
    sortByName(a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    },
    sortByDate(a, b) {
      if (a.date < b.date) {
        return -1
      }
      if (a.date > b.date) {
        return 1
      }
      return 0
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-congress-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
