<template>
  <b-sidebar
    id="detail-user-sidebar"
    :visible="isDetailUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-detail-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Detalhes do usuário
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            v-slot="{ errors }"
            name="name"
          >
            <b-form-group
              label="Nome"
              label-for="name"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                readonly
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            v-slot="{ errors }"
            name="email"
          >
            <b-form-group
              label="Email"
              label-for="email"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="email"
                v-model="form.email"
                readonly
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- CPF -->
          <validation-provider
            v-slot="{ errors }"
            name="cpf"
          >
            <b-form-group
              label="CPF"
              label-for="cpf"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="cpf"
                v-model="form.cpf"
                v-mask="'###.###.###-##'"
                readonly
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Papel -->
          <validation-provider
            v-slot="{ errors }"
            name="role"
          >
            <b-form-group
              label="Papel"
              label-for="role"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="role"
                v-model="form.role"
                :disabled="true"
                :options="[
                  {text:'Administrador',value:'ADMIN'},
                  {text:'Mestre',value:'MASTER'},
                  {text:'Pagador(a)', value: 'PAID'},
                  {text:'Comum', value: 'COMMON'},
                  {text:'Secretário(a)', value: 'SECRETARY'},
                  {text:'Secretário(a) Geral', value: 'SECRETARY-GENERAL'},
                  {text:'Tesoureiro', value: 'TREASURER'},
                  {text:'Presidente', value: 'PRESIDENT'},
                  {text:'Vice-Presidente', value: 'VICE-PRESIDENT'},
                  {text:'Palestrante', value: 'SPEAKER'},
                  {text:'Patrocinador', value: 'SPONSOR'},
                  {text:'Diretor Científico', value: 'SCIENTIFIC-DIRECTOR'},
                  {text:'Diretor do Curso', value: 'COURSE-DIRECTOR'},
                  {text:'Diretor de Comunicação', value: 'COMMUNICATION-DIRECTOR'},
                  {text:'Administrador do Congresso', value: 'CONGRESS-ADMINISTRATOR'},
                  {text:'Professor', value: 'PROFESSOR'},
                  {text:'Orientador', value: 'ADVISOR'},
                  {text:'Patrocinador de Comunicação', value: 'SPONSOR-COMMUNICATION'},
                  {text:'Patrocinador do Congresso', value: 'CONGRESS-SPONSOR'},
                  {text:'Jurídico', value: 'JURIDICAL'},
                  {text:'Presidente de Estado', value: 'STATE-PRESIDENT'},
                  {text:'Associado SNNO', value: 'SNNO-ASSOCIATE'},
                  {text:'Gerente de Arte', value: 'ART-MANAGER'},
                ]"
                :reduce="option => option.value"
                label="text"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Profession -->
          <validation-provider
            v-slot="{ errors }"
            name="profession"
            rule="required"
          >
            <b-form-group
              label="Profissão"
              label-for="profession"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-select
                id="profession"
                v-model="form.profession"
                :disabled="true"
                :options="professions"
                :state="errors.length > 0 ? false:null"
                name="profession"
                label="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Phone -->
          <validation-provider
            v-slot="{ errors }"
            name="phone"
          >
            <b-form-group
              label="Celular"
              label-for="phone"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="phone"
                v-model="form.phone"
                v-mask="'(##) #####-####'"
                readonly
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Birth Date -->
          <validation-provider
            v-slot="{ errors }"
            name="birthDate"
          >
            <b-form-group
              label="Nascimento"
              label-for="birthDate"
              :state="errors.length > 0 ? false : null"
            >
              <datepicker
                id="birthDate"
                v-model="form.birthDate"
                :disabled="true"
                :use-utc="false"
                :language="ptBR"
                input-class="form-control"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- CRM -->
          <validation-provider
            v-slot="{ errors }"
            name="crm"
            rules="integer|required_if:profession,medico_oftalmologista"
          >
            <b-form-group
              label="CRM"
              label-for="crm"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="crm"
                v-model="form.crm"
                :disabled="true"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- CRM UF -->
          <validation-provider
            v-slot="{ errors }"
            name="crm_uf"
            rules="required_if:profession,medico_oftalmologista"
          >
            <b-form-group
              label="CRM UF"
              label-for="crm_uf"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="crm_uf"
                v-model="form.crm_uf"
                :disabled="true"
                :options="states"
                :reduce="option => option.value"
                label="text"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Active -->
          <validation-provider
            v-slot="{ errors }"
            name="active"
          >
            <b-form-group
              label="Ativado"
              label-for="active"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="active"
                v-model="form.active"
                :disabled="true"
                :options="[{text:'Sim',value:1},{text:'Não', value: 0}]"
                :reduce="option => option.value"
                label="text"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip
} from 'bootstrap-vue'
import Datepicker from 'vuejs-datepicker'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    Datepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isDetailUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      ptBR,
      professions: [
        { value: 'medico_oftalmologista', text: 'Médico' },
        { value: 'academico_medicina', text: 'Estudante acadêmico' },
        { value: 'resident', text: 'Residente' },
        { value: 'palestrante', text: 'Palestrante' },
        { value: 'fellow', text: 'Fellow' },
        { value: 'sbao', text: 'Administração (SBAO)' },
        { value: 'up70year', text: 'Acima dos 70' },
        { value: 'areas_afins', text: 'Outros profissionais' },
      ],
      states: [
        { value: '', text: 'Digite o estado' },
        { value: 'AC', text: 'AC - Acre' },
        { value: 'AL', text: 'AL - Alagoas' },
        { value: 'AP', text: 'AP - Amapá' },
        { value: 'AM', text: 'AM - Amazonas' },
        { value: 'BA', text: 'BA - Bahia' },
        { value: 'CE', text: 'CE - Ceará' },
        { value: 'DF', text: 'DF - Distrito Federal' },
        { value: 'ES', text: 'ES - Espírito Santo' },
        { value: 'GO', text: 'GO - Goiás' },
        { value: 'MA', text: 'MA - Maranhão' },
        { value: 'MT', text: 'MT - Mato Grosso' },
        { value: 'MS', text: 'MS - Mato Grosso do Sul' },
        { value: 'MG', text: 'MG - Minas Gerais' },
        { value: 'PA', text: 'PA - Pará' },
        { value: 'PB', text: 'PB - Paraíba' },
        { value: 'PR', text: 'PR - Paraná' },
        { value: 'PE', text: 'PE - Pernambuco' },
        { value: 'PI', text: 'PI - Piauí' },
        { value: 'RJ', text: 'RJ - Rio de Janeiro' },
        { value: 'RN', text: 'RN - Rio Grande do Norte' },
        { value: 'RS', text: 'RS - Rio Grande do Sul' },
        { value: 'RO', text: 'RO - Rondônia' },
        { value: 'RR', text: 'RR - Roraima' },
        { value: 'SC', text: 'SC - Santa Catarina' },
        { value: 'SP', text: 'SP - São Paulo' },
        { value: 'SE', text: 'SE - Sergipe' },
        { value: 'TO', text: 'TO - Tocantins' },
      ],
      form: {
        id: null,
        name: null,
        email: null,
        cpf: null,
        role: null,
        profession: null,
        phone: null,
        birthDate: null,
        crm: null,
        crm_uf: null,
        active: null,
      },
    }
  },
  computed: {
  },
  watch: {
  },
  updated() {
    this.id = this.data.id
    this.form.name = this.data.name
    this.form.email = this.data.email
    this.form.cpf = this.data.cpf
    this.form.role = this.data.role
    this.form.profession = this.data.profession
    this.form.phone = this.data.phone
    this.form.birthDate = this.data.birthDate
    this.form.crm = this.data.crm
    this.form.crm_uf = this.data.crm_uf
    this.form.active = this.data.active
  },
  methods: {
    resetForm() {
      this.form = {
        id: null,
        name: null,
        email: null,
        cpf: null,
        role: null,
        profession: null,
        phone: null,
        birthDate: null,
        crm: null,
        crm_uf: null,
        active: null,
      }
    },
    sortByName(a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    },
    sortByDate(a, b) {
      if (a.date < b.date) {
        return -1
      }
      if (a.date > b.date) {
        return 1
      }
      return 0
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#detail-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
