var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isAddNewUserSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-user-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Adicionar usuário ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit()},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome","label-for":"name","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"email","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"id":"email","autocomplete":false,"state":errors.length > 0 ? false : null},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"cpf","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CPF","label-for":"cpf","state":errors.length > 0 ? false : null}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"id":"cpf","state":errors.length > 0 ? false : null},model:{value:(_vm.form.cpf),callback:function ($$v) {_vm.$set(_vm.form, "cpf", $$v)},expression:"form.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Papel","label-for":"role","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"role","options":[
                {text:'Administrador',value:'ADMIN'},
                {text:'Mestre',value:'MASTER'},
                {text:'Pagador(a)', value: 'PAID'},
                {text:'Comum', value: 'COMMON'},
                {text:'Secretário(a)', value: 'SECRETARY'},
                {text:'Secretário(a) Geral', value: 'SECRETARY-GENERAL'},
                {text:'Tesoureiro', value: 'TREASURER'},
                {text:'Presidente', value: 'PRESIDENT'},
                {text:'Vice-Presidente', value: 'VICE-PRESIDENT'},
                {text:'Palestrante', value: 'SPEAKER'},
                {text:'Patrocinador', value: 'SPONSOR'},
                {text:'Diretor Científico', value: 'SCIENTIFIC-DIRECTOR'},
                {text:'Diretor do Curso', value: 'COURSE-DIRECTOR'},
                {text:'Diretor de Comunicação', value: 'COMMUNICATION-DIRECTOR'},
                {text:'Administrador do Congresso', value: 'CONGRESS-ADMINISTRATOR'},
                {text:'Professor', value: 'PROFESSOR'},
                {text:'Orientador', value: 'ADVISOR'},
                {text:'Patrocinador de Comunicação', value: 'SPONSOR-COMMUNICATION'},
                {text:'Patrocinador do Congresso', value: 'CONGRESS-SPONSOR'},
                {text:'Jurídico', value: 'JURIDICAL'},
                {text:'Presidente de Estado', value: 'STATE-PRESIDENT'},
                {text:'Associado SNNO', value: 'SNNO-ASSOCIATE'},
                {text:'Gerente de Arte', value: 'ART-MANAGER'} ],"reduce":function (option) { return option.value; },"label":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"profession","rule":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Profissão","label-for":"profession","state":errors.length > 0 ? false : null}},[_c('b-form-select',{attrs:{"id":"profession","options":_vm.professions,"state":errors.length > 0 ? false:null,"name":"profession","label":"text"},model:{value:(_vm.form.profession),callback:function ($$v) {_vm.$set(_vm.form, "profession", $$v)},expression:"form.profession"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Celular","label-for":"phone","state":errors.length > 0 ? false : null}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],attrs:{"id":"phone","state":errors.length > 0 ? false : null},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"birthDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nascimento","label-for":"birthDate","state":errors.length > 0 ? false : null}},[_c('datepicker',{attrs:{"id":"birthDate","input-class":"form-control","language":_vm.ptBR,"state":errors.length > 0 ? false : null},model:{value:(_vm.form.birthDate),callback:function ($$v) {_vm.$set(_vm.form, "birthDate", $$v)},expression:"form.birthDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"crm","rules":"integer|required_if:profession,medico_oftalmologista"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CRM","label-for":"crm","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"id":"crm","state":errors.length > 0 ? false : null},model:{value:(_vm.form.crm),callback:function ($$v) {_vm.$set(_vm.form, "crm", $$v)},expression:"form.crm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"crm_uf","rules":"required_if:profession,medico_oftalmologista"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CRM UF","label-for":"crm_uf","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"crm_uf","options":_vm.states,"reduce":function (option) { return option.value; },"label":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.form.crm_uf),callback:function ($$v) {_vm.$set(_vm.form, "crm_uf", $$v)},expression:"form.crm_uf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ativado","label-for":"active","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"active","options":[{text:'Sim',value:1},{text:'Não', value: 0}],"reduce":function (option) { return option.value; },"label":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.form.active),callback:function ($$v) {_vm.$set(_vm.form, "active", $$v)},expression:"form.active"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"register-password","label":"Senha"}},[_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","autocomplete":'new-password',"type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"repeatPassword","vid":"repeatPassword","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"register-repeat_password","label":"Repetir senha"}},[_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-repeatPassword","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"autocomplete":'new-password',"name":"register-repeat_password","placeholder":"············"},model:{value:(_vm.form.repeatPassword),callback:function ($$v) {_vm.$set(_vm.form, "repeatPassword", $$v)},expression:"form.repeatPassword"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.isBusy}},[(_vm.isBusy)?_c('b-spinner'):[_vm._v(" Adicionar ")]],2),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancelar ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }